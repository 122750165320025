import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import {
  logo,
  hero,
  heroText,
  mobileHero,
  mobileHeroText,
  kameiRoyale,
  richmond,
  broadway,
} from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView w="100%" zIndex={90} column justifyBetween>
          <Header />
          <CFImage
            w="100%"
            src={mobileHero}
            alt="Kamei Japanese Restaurant Logo"
            zIndex={98}
          />
          <CFView
            column
            center
            w="100%"
            maxWidth="1400px"
            bg="white"
            color="#646464"
            pt="15px"
          >
            <CFView textCenter ph="10%">
              <CFText h7>
                Please enter one of the following Kamei websites /
                してください次のいずれか龜井鮨のウェブサイトを入力 /
                請進入其中一個龜井鮨網站
              </CFText>
            </CFView>
            <CFView
              w="100%"
              column
              justifyBetween
              alignCenter
              textCenter
              pt="30px"
              ph="15%"
            >
              <CFLink target="_blank" href="https://kameibroadway.ca/">
                <CFImage
                  w="65%"
                  mb="40px"
                  src={broadway}
                  alt="Kamei Japanese Restaurant Logo"
                  zIndex={98}
                />
              </CFLink>
              <CFLink target="_blank" href="http://kameiroyale.com/">
                <CFImage
                  w="70%"
                  mb="42px"
                  src={kameiRoyale}
                  alt="Kamei Japanese Restaurant Logo"
                  zIndex={98}
                />
              </CFLink>
              <CFLink target="_blank" href="https://ebisurichmond.netlify.app/">
                <CFImage
                  w="64%"
                  mb="30px"
                  src={richmond}
                  alt="Kamei Japanese Restaurant Logo"
                  zIndex={98}
                />
              </CFLink>
            </CFView>
          </CFView>
          <CFView textCenter white pv="8px" ph="15px">
            <CFText h7>
              © 2022 KAMEI JAPANESE RESTAURANT GROUP. ALL RIGHTS RESERVED.
            </CFText>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" zIndex={90} column justifyBetween>
          <Header />
          <CFImage
            w="100%"
            src={hero}
            alt="Kamei Japanese Restaurant Logo"
            zIndex={98}
          />
          <CFView
            column
            center
            w="100%"
            maxWidth="1400px"
            bg="white"
            color="#646464"
            pv="15px"
          >
            <CFView textCenter ph="10%">
              <CFText h6>
                Please enter one of the following Kamei websites /
                してください次のいずれか龜井鮨のウェブサイトを入力 /
                請進入其中一個龜井鮨網站
              </CFText>
            </CFView>
            <CFView
              w="100%"
              row
              justifyBetween
              alignCenter
              textCenter
              pt="2%"
              pb="5px"
              ph="10%"
            >
              <CFLink target="_blank" href="https://kameibroadway.ca/">
                <CFImage
                  w="50%"
                  src={broadway}
                  alt="Kamei Japanese Restaurant Logo"
                  zIndex={98}
                />
              </CFLink>
              <CFLink target="_blank" href="http://kameiroyale.com/">
                <CFImage
                  w="57%"
                  src={kameiRoyale}
                  alt="Kamei Japanese Restaurant Logo"
                  zIndex={98}
                  mb="5px"
                />
              </CFLink>
              <CFLink target="_blank" href="https://ebisurichmond.netlify.app/">
                <CFImage
                  w="47%"
                  src={richmond}
                  alt="Kamei Japanese Restaurant Logo"
                  zIndex={98}
                />
              </CFLink>
            </CFView>
          </CFView>
          <CFView textCenter white pv="8px">
            <CFText h6>
              © 2022 KAMEI JAPANESE RESTAURANT GROUP. ALL RIGHTS RESERVED.
            </CFText>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
